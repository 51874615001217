import type { TaxCountryCode, TaxCustom } from '~/types/tax.types'
import type { Database } from '~/types/database.types'

const shouldAddUserCountryTax = ref<boolean>(false)

export async function useUserCountryTax(userInfos: Ref<Database['public']['Tables']['user_infos']['Row'] | null>) {
  // Loading the saved value from cookie (and updating it when necessary)
  useCookieSaving<boolean>('shouldAddUserCountryTax', shouldAddUserCountryTax)

  // Get user tax data
  const userTaxCountryCode = computed<TaxCountryCode | null>(() => userInfos.value?.tax_country_code as TaxCountryCode | null ?? null)
  const userTaxCustom = computed<TaxCustom | null>(() => userInfos.value?.tax_custom as TaxCustom | null ?? null)

  return {
    shouldAddUserCountryTax,
    userTaxCountryCode,
    userTaxCustom,
  }
}